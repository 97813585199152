import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { storage, db } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import ReactCrop, { Crop, PercentCrop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './Impressum.css';
import defaultLogoImage from '../assets/logo_default.jpg'; // Path to your default logo image
// import { useFirebaseServicesNew } from '../firebaseConfig'; // Import the hook for Firebase services

interface ImpressumProps {
  user: User;
  setBusinessImage: (url: string) => void;
}

const Impressum: React.FC<ImpressumProps> = ({ user, setBusinessImage }) => {
  const [impressumText, setImpressumText] = useState<string>('');
  const [localBusinessImage, setLocalBusinessImage] = useState<string>(defaultLogoImage);
  const [upImg, setUpImg] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop | undefined>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | undefined>();
  const imageRef = useRef<HTMLImageElement | null>(null);
  const navigate = useNavigate();
  // const firebaseServices = useFirebaseServicesNew();

  useEffect(() => {
    const fetchUserProfile = async () => {
      // if (firebaseServices?.db) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setLocalBusinessImage(userData.businessPhotoURL || defaultLogoImage);
          setImpressumText(userData.imprint || ''); // Load the imprint field from Firestore
        }
      // }
    };

    fetchUserProfile();
  }, [user]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result as string));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1 / 1));
  };

  const onCropComplete = (crop: PixelCrop) => {
    setCompletedCrop(crop);
  };

  const onCropChange = (crop: Crop, percentCrop: PercentCrop) => {
    setCrop(percentCrop);
  };

  const handleSaveCrop = async () => {
    if (completedCrop && imageRef.current) {
      const canvas = document.createElement('canvas');
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(
          imageRef.current,
          completedCrop.x * scaleX,
          completedCrop.y * scaleY,
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
          0,
          0,
          completedCrop.width,
          completedCrop.height
        );

        canvas.toBlob(async (blob) => {
          if (blob) {
            // if (firebaseServices?.storage && firebaseServices?.db) {
              const newPath = `businessImages/${user.uid}/image_business_seller_${user.uid}.jpg`;
              const storageRef = ref(storage, newPath);
              await uploadBytes(storageRef, blob);
              const downloadURL = await getDownloadURL(storageRef);

              await updateDoc(doc(db, 'users', user.uid), {
                businessPhotoURL: downloadURL,
              });

              setLocalBusinessImage(downloadURL);
              setBusinessImage(downloadURL); // Update the business image in the parent component
              setUpImg(null);
            // }
          }
        }, 'image/jpeg');
      }
    }
  };

  const handleSave = async () => {
    if (impressumText.trim() === '') {
      alert('Bitte geben Sie hier Ihr Impressum an.');
      return;
    }
    // if (firebaseServices?.db) {
      await updateDoc(doc(db, 'users', user.uid), {
        imprint: impressumText, // Save the impressumText under "imprint" in Firestore
      });
      navigate('/profile'); // Redirect back to profile page after saving
    // }
  };

  const handleCancel = () => {
    navigate('/profile'); // Redirect back to profile page
  };

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  return (
    <div className="impressum-container">
      <div className="impressum-header">
        <button className="back-button" onClick={handleCancel}>Back</button>
        <h1>IMPRESSUM</h1>
      </div>
      <label htmlFor="file-input">
        <div className="logo-image-wrapper">
          <img src={localBusinessImage} alt="Logo" className="logo-image" />
        </div>
      </label>
      <input
        id="file-input"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
      {upImg && (
        <div>
          <ReactCrop
            crop={crop}
            ruleOfThirds
            onComplete={(c) => onCropComplete(c as PixelCrop)}
            onChange={(c) => onCropChange(c as Crop, c as unknown as PercentCrop)}
            aspect={1 / 1}
          >
            <img
              ref={imageRef}
              alt="Crop me"
              src={upImg}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <button onClick={handleSaveCrop} className="impressum-button">Speichern</button>
        </div>
      )}
      <textarea
        className="impressum-textarea"
        placeholder="Impressum"
        value={impressumText}
        onChange={(e) => setImpressumText(e.target.value)}
      />
      <div className="impressum-buttons">
        <button className="impressum-button" onClick={handleSave}>Übernehmen</button>
        <button className="impressum-button" onClick={handleCancel}>Abbrechen</button>
      </div>
    </div>
  );
};

export default Impressum;
