import React, { ChangeEvent } from 'react';
import { PropertyVehicleState } from './PropertyVehicleState';
import { Property }  from './DetailView';

interface DropdownOption {
  value: string;
  label: string;
}

interface DropdownFieldProps {
  label: string;
  fieldName: keyof Property;
  options: DropdownOption[];
  value: string | number;
  onChange: (name: keyof Property, value: string) => void;
}

const DropdownField: React.FC<DropdownFieldProps> = ({ label, fieldName, options, value, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    onChange(fieldName, selectedValue);
  };

  return (
      <label className="horizontal-label">
        <span className="label-text">{label}</span>
        
        <select className="editable-input" value={value} onChange={handleChange}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <span className="edit-icon">
        <i className="fa fa-edit"></i> {/* FontAwesome edit icon */}
      </span>
      </label>
  );
};

export default DropdownField;
