// src/components/SendSupport.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import './SendSupport.css';
import { User } from 'firebase/auth';
// import { useFirebaseServicesNew } from '../firebaseConfig'; // Import the hook for Firebase services

interface SendSupportProps {
  user: User | null;
  version: string;
  buildNumber: string;
}

const SendSupport: React.FC<SendSupportProps> = ({ user, version, buildNumber }) => {
  const [supportMessage, setSupportMessage] = useState('');
  const navigate = useNavigate();
  // const firebaseServices = useFirebaseServicesNew();

  const handleSendSupport = async () => {
    if (supportMessage.trim() === '') {
      alert('Bitte geben Sie Ihr Anliegen ein.');
      return;
    }

    if (user) {
      try {
        // if (firebaseServices?.db) {
          await addDoc(collection(db, 'support'), {
            uid: user.uid,
            supportMessage,
            version,
            buildNumber,
            timestamp: new Date()
          });
          alert('Support-Anfrage erfolgreich gesendet');
          navigate('/profile');
        // }
      } catch (error) {
        console.error('Error sending support request:', error);
        alert('Fehler beim Senden der Support-Anfrage');
      }
    }
  };

  return (
    <div className="send-support-container">
      {/* <div className="send-support-header">
        <h1>SUPPORT</h1>
      </div> */}
      <div className="send-support-form">
        <p>Hier können Sie sich an den Support von Automatch wenden. Senden Sie uns Ihr Anliegen und wir werden uns darum kümmern.</p>
        <textarea
          className="send-support-textarea"
          value={supportMessage}
          onChange={(e) => setSupportMessage(e.target.value)}
          placeholder="Support"
          rows={10}
        />
        <div className="send-support-buttons">
          <button onClick={handleSendSupport} className="send-support-button">Senden</button>
          <button onClick={() => navigate('/profile')} className="send-support-button cancel-button">Abbrechen</button>
        </div>
      </div>
    </div>
  );
};

export default SendSupport;
