// src/components/Login.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { auth, googleProvider, db, requestPermission } from '../firebaseConfig';
import { auth, googleProvider, db } from '../firebaseConfig';
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import './Login.css';
// import { useFirebaseServicesNew } from '../firebaseConfig'; // Import the hook for Firebase services

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  // const firebaseServices = useFirebaseServicesNew();

  const handleGoogleLogin = async () => {
    try {
      // if (firebaseServices?.auth && firebaseServices?.db && firebaseServices?.googleProvider) {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;
        const userSnapshot = await getDoc(doc(db, 'users', user.uid));
        // const token = await requestPermission();

        if (userSnapshot.exists()) {
          await setDoc(doc(db, 'users', user.uid), {
            // token,
            lastSeen: Timestamp.now().toMillis(),
          }, { merge: true });
          navigate('/');
        } else {
          const fullName = user.displayName ?? '';
          const names = fullName.split(' ');
          const firstName = names.length > 0 ? names[0] : '';
          const lastName = names.length > 1 ? names.slice(1).join(' ') : '';

          await setDoc(doc(db, 'users', user.uid), {
            firstName,
            lastName,
            email: user.email,
            uid: user.uid,
            createdAt: Timestamp.now().toMillis(),
            changedAt: Timestamp.now().toMillis(),
            role: 'SELLER',
            sellerPhotoURL: user.photoURL ?? '',
            buyerPhotoURL: user.photoURL ?? '',
            lastSeen: Timestamp.now().toMillis(),
            chattingWith: null,
            // token,
            accountType: 'PRIVATE',
          });
          navigate('/');
        }
      // }
    } catch (error) {
      console.error('Error during Google sign in:', error);
      setError('Fehler bei der Google-Anmeldung. Bitte versuchen Sie es erneut.');
    }
  };

  const handleEmailLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // if (firebaseServices?.auth && firebaseServices?.db) {
        const result = await signInWithEmailAndPassword(auth, email, password);
        const user = result.user;
        // const token = await requestPermission();

        await setDoc(doc(db, 'users', user.uid), {
          // token,
          lastSeen: Date.now(),
        }, { merge: true });

        navigate('/');
      // }
    } catch (error) {
      console.error('Error during email sign in:', error);
      setError('Fehler bei der E-Mail-Anmeldung. Bitte überprüfen Sie Ihre Anmeldedaten und versuchen Sie es erneut.');
    }
  };

  const handleRegisterClick = () => {
    navigate('/register');
  };

  return (
    <div className="login-container">
      <h2>Anmeldung</h2>
      <p>Bitte registrieren Sie sich bei Automatch oder melden Sie sich mit Ihren Anmeldedaten an.</p>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleEmailLogin} className="login-form">
        <div className="form-group">
          <label htmlFor="email">E-Mail</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Passwort</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">Anmelden</button>
      </form>
      <p>Passwort vergessen?</p>
      <p>Sie haben noch kein Nutzerkonto?</p>
      <div className="button-group">
        <button onClick={handleRegisterClick} className="register-button">Registrieren</button>
        <button onClick={handleGoogleLogin} className="google-button">Sign in with Google</button>
      </div>
    </div>
  );
};

export default Login;
