import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import { User } from 'firebase/auth';
import { carBrandModelsMap } from '../data/carBrandModelsMap';
import './AddCar.css';
import AddressSearch from './AddressSearch'; // Import the AddressSearch component
// import { useFirebaseServicesNew } from '../firebaseConfig'; // Import the hook for Firebase services

interface AddCarProps {
  user: User | null;
}

const AddCar: React.FC<AddCarProps> = ({ user }) => {
  const [carData, setCarData] = useState({
    type: '',
    model: '',
    price: '',
    city: '',
    state: '', // Add state to carData
    title: '',
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    performance: '',
    mileage: '',
    radar: false,
    usedCar: false,
  });

  const [models, setModels] = useState<string[]>([]);
  const [isValidAddress, setIsValidAddress] = useState(false); // Track if the address is valid
  const [addressFeedback, setAddressFeedback] = useState(''); // Feedback for address input
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [propertyType, setPropertyType] = useState<string>('');

  const navigate = useNavigate();

  const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const years = Array.from(new Array(new Date().getFullYear() - 1899), (x, i) => (1900 + i).toString()).reverse();

  const userRef = useRef(user);

  // const firebaseServices = useFirebaseServicesNew();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (userRef.current) {
        const docRef = doc(db, 'users', userRef.current.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setFirstName(userData.firstName || '');
          setLastName(userData.lastName || '');
          setToken(userData.token || '');
          setPropertyType(userData.accountType || '');
        }
      }
    };

    // const fetchUserProfile = async () => {
    //   if (user) {
    //     try {
    //       if (userRef.current) {
    //         const response = await fetch(`/api/user/${userRef.current.uid}`);
    //         if (response.ok) {
    //           const userData = await response.json();
    //           setFirstName(userData.firstName || '');
    //           setLastName(userData.lastName || '');
    //           setToken(userData.token || '');
    //           setPropertyType(userData.accountType || '');
    //         } else {
    //           console.error('Fehler beim Abrufen der Benutzerdaten');
    //         }
    //       }
    //     } catch (error) {
    //       console.error('Fehler beim Abrufen der Benutzerdaten:', error);
    //     }
    //   }
    // };

    fetchUserProfile();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCarData({
      ...carData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCarData({
      ...carData,
      [name]: checked,
    });
  };

  const handleBrandChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBrand = e.target.value;
    setCarData({
      ...carData,
      type: selectedBrand,
      model: '',
    });
    setModels(carBrandModelsMap[selectedBrand] || []);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValidAddress) {
      setAddressFeedback('Bitte wählen Sie einen gültigen Ort aus der Liste aus.');
      return;
    }
    if (user) {
      // if (firebaseServices?.db) {
        const docRef = doc(collection(db, 'properties'));
        const uid = docRef.id;
        const timestamp = Timestamp.now().toMillis();
        await setDoc(docRef, {
          ...carData,
          uid: uid,
          price: Number(carData.price),
          year: Number(carData.year),
          month: Number(carData.month),
          performance: Number(carData.performance),
          mileage: Number(carData.mileage),
          userUID: user.uid,
          firstName: firstName,
          lastName: lastName,
          createdAt: timestamp,
          changedAt: timestamp,
          token: token,
          accountType: propertyType,
        });
        navigate('/');
      // }
    }
  };

  const handleSelectAddress = (city: string, state: string) => {
    setCarData({ ...carData, city, state });
    setIsValidAddress(true);
    setAddressFeedback('');
  };

  const handleAddressInput = () => {
    setIsValidAddress(false);
    setAddressFeedback('Bitte wählen Sie einen gültigen Ort aus der Liste aus.');
  };

  return (
    <div className="add-car-container">
      <h2>Neues Fahrzeug hinzufügen</h2>
      <form onSubmit={handleSubmit} className="add-car-form">
        <div className="form-group-horizontal">
          <label className="horizontal-label">
            <span>Gebrauchtwagen</span>
            <input type="checkbox" id="usedCar" name="usedCar" checked={carData.usedCar} onChange={handleCheckboxChange} />
            <span className={`horizontal-span ${carData.usedCar ? '' : 'no'}`}>{carData.usedCar ? "JA" : "NEIN"}</span>
          </label>
        </div>
        <div className="form-group-horizontal">
          <label className="horizontal-label">
            <span>Radar</span>
            <input type="checkbox" id="radar" name="radar" checked={carData.radar} onChange={handleCheckboxChange} />
            <span className={`horizontal-span ${carData.radar ? '' : 'no'}`}>{carData.radar ? "JA" : "NEIN"}</span>
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="title">Titel</label>
          <input type="text" id="title" name="title" value={carData.title} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="type">Marke</label>
          <select id="type" name="type" value={carData.type} onChange={handleBrandChange} required>
            {Object.keys(carBrandModelsMap).map(brand => (
              <option key={brand} value={brand}>{brand}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="model">Modell</label>
          <select id="model" name="model" value={carData.model} onChange={handleChange} required>
            {models.map(model => (
              <option key={model} value={model}>{model}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="price">Preis (€)</label>
          <input type="number" id="price" name="price" value={carData.price} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="city">Ort</label>
          <AddressSearch
            onSelectAddress={handleSelectAddress}
            onInputAddress={handleAddressInput}
            isValidAddress={isValidAddress}
            initialValue={''}
          />
          {!isValidAddress && <span className="address-feedback">{addressFeedback}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="year">Erstzulassungsdatum</label>
          <div className="date-inputs">
            <select id="year" name="year" value={carData.year} onChange={handleChange} required>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            <select id="month" name="month" value={carData.month} onChange={handleChange} required>
              {months.map(month => (
                <option key={month} value={month}>{month}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="performance">Leistung (PS)</label>
          <input type="number" id="performance" name="performance" value={carData.performance} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="mileage">Kilometerstand</label>
          <input type="number" id="mileage" name="mileage" value={carData.mileage} onChange={handleChange} required />
        </div>
        <button type="submit" className="add-car-button">Fahrzeug hinzufügen</button>
      </form>
    </div>
  );
};

export default AddCar;
