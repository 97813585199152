import React, { ChangeEvent, useState, useEffect } from 'react';
import { Property } from './DetailView';

interface DropdownFieldWithAmountProps {
  label: string;
  fieldName: keyof Property;
  value: string | number | null;
  onChange: (fieldName: keyof Property, value: string | number | null) => void;
}

const DropdownFieldWithAmount: React.FC<DropdownFieldWithAmountProps> = ({ label, fieldName, value, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<string | number | null>(value);
  const [amount, setAmount] = useState<number | ''>('');

  useEffect(() => {
    // Initialisieren basierend auf dem übergebenen Wert
    if (value === null) {
      setSelectedOption('keineAngabe');
    } else if (value === 0) {
      setSelectedOption('0'); // Jetzt als String "0"
    } else if (typeof value === 'number') {
      setSelectedOption('extra');
      setAmount(value); // Betrag wird gesetzt
    }
  }, [value]);

  const handleDropdownChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'keineAngabe') {
      setSelectedOption('keineAngabe');
      onChange(fieldName, null); // NULL = keine Angabe
    } else if (selectedValue === '0') {
      setSelectedOption('0');
      onChange(fieldName, 0); // 0 = inklusive
    } else if (selectedValue === 'extra') {
      setSelectedOption('extra'); // Für den Betrag
    }
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const amountValue = parseFloat(event.target.value);
    setAmount(amountValue || ''); // Setze den Betrag
    onChange(fieldName, amountValue || ''); // Übergebe den Betrag
  };

  return (
    <div className="form-group-horizontal">
      <label className="horizontal-label">
        <span className="label-text">{label}</span>
        <select
          className="editable-input"
          value={selectedOption === 'keineAngabe' ? 'keineAngabe' : selectedOption?.toString()}
          onChange={handleDropdownChange}
        >
          <option value="keineAngabe">Keine Angabe</option>
          <option value="0">Inklusive</option>
          <option value="extra">Extra (Betrag angeben)</option>
        </select>
      </label>

      {selectedOption === 'extra' && (
        <input
          type="number"
          className="editable-input"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Betrag in EUR"
        />
      )}
    </div>
  );
};

export default DropdownFieldWithAmount;
