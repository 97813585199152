// src/components/Header.tsx
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.jpg';
import defaultProfileImage from '../assets/profile_default.png';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { User } from 'firebase/auth';
import { FaArrowLeft } from 'react-icons/fa'; // Import des Icons für den Zurück-Pfeil
// import { useFirebaseServicesNew } from '../firebaseConfig'; // Import the hook for Firebase services

interface HeaderProps {
  user: User | null;
  setUser: (user: User | null) => void;
  profileImage: string | null;
}

const Header: React.FC<HeaderProps> = ({ user, setUser, profileImage }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const firebaseServices = useFirebaseServicesNew();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleLogoutClick = async () => {
    try {
      // if (firebaseServices?.auth) {
        await signOut(auth);
        console.log('User signed out');
        setUser(null);
      // }
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleAddCarClick = () => {
    navigate('/add-car');
  };

  const handleImportClick = () => {
    // Hier kannst du die Logik für den Import-Button hinzufügen
    navigate('/import'); // Beispiel: Navigieren zur Import-Seite
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const getTitle = () => {
    console.log("Location PathName:", location.pathname);
    if (location.pathname.includes('/images/')) {
      return 'Bilder';
    }
    if (location.pathname.includes('/upload-images/')) {
      return 'Bilder hochladen';
    }
    switch (location.pathname) {
      case '/':
        return 'Fuhrpark';
      case '/profile':
        return 'Profil';
      case '/add-car':
        return 'Auto hinzufügen';
      case '/login':
        return 'Anmeldung';
      case '/register':
        return 'Registrierung';
      case '/feedback':
        return 'Kontakt / Feedback';
      case '/support':
        return 'Support';
      default:
        return 'Detailansicht';
    }
  };

  return (
    <header className="header">
      {location.pathname !== '/' ? (
        <div className="back-button-container">
          <button className="back-button" onClick={handleBackClick}>
            <FaArrowLeft />
          </button>
        </div>
      ) : (
        <div className="logo-container">
          <a href="https://automatchapp.de/" target="_blank" rel="noopener noreferrer" className='logo'>
            <img src={logo} alt="Logo" className="logo" />
          </a>
        </div>
      )}
      <h1 className="header-title">
        <span className="auto">AUTO</span><span className="match">MATCH</span>
      </h1>
      {user ? (
        <div className="header-right">
          <button className="import-button" onClick={handleImportClick}>
            Import
          </button>
          <img
            src={profileImage || defaultProfileImage}
            alt="Profile"
            className="profile-image"
            onClick={handleProfileClick}
          />
        </div>
      ) : (
        <button className="header-login-button" onClick={handleLoginClick}>
          Login / Anmelden
        </button>
      )}
    </header>
  );
};

export default Header;
