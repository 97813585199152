// src/data/carBrandModelsMap.ts
export const carBrandModelsMap: { [key: string]: string[] } = {
    "Keine Auswahl": ["Alle Modelle"],
    "Alfa Romeo": ["Alle Modelle", "Giulia", "Stelvio", "Giulietta", "4C", "MiTo"],
    "Aston Martin": ["Alle Modelle", "DB11", "Vantage", "DBS Superleggera", "Rapide", "DBX", "Valkyrie"],
    "Audi": ["Alle Modelle", "A1", "A3", "A4", "A5", "A6", "A7", "A8", "Q2", "Q3", "Q4 e-tron", "Q5", "Q7", "Q8", "R8", "TT", "e-tron", "RS3", "RS4", "RS5", "RS6", "RS7", "S3", "S4", "S5", "S6", "S7", "S8", "SQ2", "SQ5", "SQ7", "SQ8"],
    "Bentley": ["Alle Modelle", "Continental GT", "Bentayga", "Flying Spur", "Mulsanne"],
    "BMW": ["Alle Modelle", "1 Series", "2 Series", "3 Series", "4 Series", "5 Series", "6 Series", "7 Series", "8 Series", "X1", "X2", "X3", "X4", "X5", "X6", "X7", "Z4", "i3", "i4", "i8", "iX3", "iX5", "M2", "M3", "M4", "M5", "M8", "X3 M", "X4 M", "X5 M", "X6 M", "Z4 M"],
    "Bugatti": ["Alle Modelle", "Chiron", "Veyron", "Divo", "Centodieci"],
    "Chevrolet": ["Alle Modelle", "Camaro", "Corvette", "Blazer", "Bolt", "Equinox", "Impala", "Malibu", "Silverado", "Suburban", "Tahoe", "Trailblazer", "Traverse", "Trax"],
    "Citroen": ["Alle Modelle", "Berlingo", "C1", "C3", "C3 Aircross", "C4", "C4 Cactus", "C5 Aircross", "SpaceTourer", "Grand C4 SpaceTourer", "C4 Picasso", "Jumpy"],
    "Dacia": ["Alle Modelle", "Duster", "Sandero", "Logan", "Lodgy", "Dokker"],
    "Dodge": ["Alle Modelle", "Charger", "Challenger", "Durango", "Grand Caravan", "Journey", "Viper"],
    "Ferrari": ["Alle Modelle", "812 Superfast", "SF90 Stradale", "Roma", "F8 Tributo", "Portofino", "Monza SP1", "Monza SP2", "GTC4Lusso", "LaFerrari"],
    "Fiat": ["Alle Modelle", "500", "Panda", "Tipo", "500L", "500X", "Ducato", "Doblo", "Punto", "Qubo"],
    "Ford": ["Alle Modelle", "Fiesta", "Focus", "Mustang", "EcoSport", "Kuga", "Puma", "Edge", "Explorer", "Ranger", "Mondeo", "Galaxy", "S-MAX", "Transit", "Bronco", "F-150", "GT", "Ka+", "Tourneo"],
    "Honda": ["Alle Modelle", "Civic", "Jazz", "Accord", "HR-V", "CR-V", "NSX", "Fit", "Odyssey", "Pilot", "Ridgeline"],
    "Hyundai": ["Alle Modelle", "i10", "i20", "i30", "i40", "IX20", "Tucson", "Santa Fe", "Kona", "Palisade", "Venue", "Elantra", "Sonata", "Nexo", "Veloster"],
    "Infiniti": ["Alle Modelle", "Q50", "Q60", "Q70", "QX30", "QX50", "QX60", "QX80"],
    "Jaguar": ["Alle Modelle", "XE", "XF", "XJ", "E-PACE", "F-PACE", "I-PACE", "F-TYPE"],
    "Jeep": ["Alle Modelle", "Renegade", "Compass", "Cherokee", "Grand Cherokee", "Wrangler", "Gladiator"],
    "Kia": ["Alle Modelle", "Rio", "Picanto", "Soul", "Ceed", "ProCeed", "Optima", "Stinger", "Sportage", "Sorento", "Niro", "Telluride", "Carnival"],
    "Lamborghini": ["Alle Modelle", "Huracan", "Aventador", "Urus", "Sian", "Gallardo", "Murcielago"],
    "Lancia": ["Alle Modelle", "Ypsilon"],
    "Land Rover": ["Alle Modelle", "Defender", "Discovery", "Discovery Sport", "Range Rover", "Range Rover Sport", "Range Rover Evoque", "Range Rover Velar"],
    "Lexus": ["Alle Modelle", "IS", "ES", "GS", "LS", "UX", "NX", "RX", "GX", "LX", "RC", "LC", "LFA"],
    "Maserati": ["Alle Modelle", "Ghibli", "Quattroporte", "Levante", "GranTurismo", "GranCabrio", "MC20"],
    "Mazda": ["Alle Modelle", "2", "3", "6", "CX-3", "CX-30", "CX-5", "CX-9", "MX-5"],
    "McLaren": ["Alle Modelle", "540C", "570S", "570GT", "600LT", "720S", "765LT", "GT", "Senna", "Speedtail", "Elva"],
    "Mercedes-Benz": ["Alle Modelle", "A-Class", "B-Class", "C-Class", "E-Class", "S-Class", "CLA", "CLS", "GLA", "GLB", "GLC", "GLE", "GLS", "G-Class", "SL", "SLC", "AMG GT", "EQC", "EQV", "V-Class"],
    "Mini": ["Alle Modelle", "Cooper", "Countryman", "Clubman", "Paceman", "Roadster", "Coupe"],
    "Mitsubishi": ["Alle Modelle", "Mirage", "Eclipse Cross", "Outlander", "Pajero", "L200", "ASX", "i-MiEV"],
    "Nissan": ["Alle Modelle", "Micra", "Leaf", "Juke", "Qashqai", "X-Trail", "370Z", "GT-R", "Murano", "Pathfinder", "Armada", "Rogue", "Versa", "Sentra", "Altima", "Maxima", "Titan"],
    "Opel": ["Alle Modelle", "Corsa", "Astra", "Insignia", "Crossland", "Grandland X", "Mokka", "Zafira", "Adam", "Combo", "Vivaro", "Movano"],
    "Peugeot": ["Alle Modelle", "108", "208", "2008", "308", "3008", "508", "5008", "Traveller", "Rifter"],
    "Porsche": ["Alle Modelle", "911", "718 Boxster", "718 Cayman", "Panamera", "Macan", "Cayenne", "Taycan"],
    "Renault": ["Alle Modelle", "Twingo", "Clio", "Captur", "Megane", "Scenic", "Kadjar", "Talisman", "Koleos", "Espace", "Zoe", "Kangoo", "Trafic", "Master"],
    "Rolls-Royce": ["Alle Modelle", "Phantom", "Ghost", "Wraith", "Dawn", "Cullinan"],
    "Seat": ["Alle Modelle", "Mii", "Ibiza", "Leon", "Arona", "Ateca", "Tarraco", "Alhambra", "Cupra"],
    "Skoda": ["Alle Modelle", "Citigo", "Fabia", "Scala", "Octavia", "Superb", "Kamiq", "Karoq", "Kodiaq", "Enyaq iV"],
    "Smart": ["Alle Modelle", "Fortwo", "Forfour"],
    "SSC": ["Alle Modelle", "Tuatara"],
    "Subaru": ["Alle Modelle", "Impreza", "Legacy", "Forester", "Outback", "XV", "BRZ", "WRX STI", "Ascent"],
    "Suzuki": ["Alle Modelle", "Alto", "Swift", "Baleno", "Ignis", "S-Cross", "Vitara", "Jimny"],
    "Tesla": ["Alle Modelle", "Model S", "Model 3", "Model X", "Model Y", "Roadster", "Cybertruck"],
    "Toyota": ["Alle Modelle", "Aygo", "Yaris", "Corolla", "Camry", "C-HR", "RAV4", "Highlander", "Land Cruiser", "Supra", "Hilux", "Proace", "Prius", "Mirai"],
    "Volkswagen": ["Alle Modelle", "Up", "Polo", "Golf", "T-Cross", "T-Roc", "Tiguan", "Touran", "Passat", "Arteon", "Touareg", "Sharan", "Caddy", "Transporter", "Caravelle", "Multivan", "Amarok", "ID.3", "ID.4"],
    "Volvo": ["Alle Modelle", "S60", "S90", "V60", "V90", "XC40", "XC60", "XC90"]
  };
  