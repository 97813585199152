// src/components/Footer.tsx
import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p>
        <a href="https://automatchapp.de/impressum" target='_blank' rel="noopener noreferrer">Impressum</a> | 
        <a href="https://automatchapp.de/datenschutz" target='_blank' rel="noopener noreferrer">Datenschutz</a>
      </p>
    </footer>
  );
};

export default Footer;
