import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import GridView from './components/GridView';
import Footer from './components/Footer';
import DetailView from './components/DetailView';
import AddCar from './components/AddCar';
import { useAuth } from './hooks/useAuth';
import { User } from 'firebase/auth';
import Login from './components/Login';
import Register from './components/Register';
import Profile from './components/Profile';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import UploadImages from './components/UploadImages';
import Images from './components/Images';
import ProtectedRoute from './components/ProtectedRoute';
import SendFeedback from './components/SendFeedback';
import SendSupport from './components/SendSupport';
import Impressum from './components/Impressum'; // Import the Impressum component
import ImportPage from './components/ImportPage';
// import MapsComponent from './MapsComponent';

function App() {
  const { user, loading } = useAuth();
  const [currentUser, setCurrentUser] = useState<User | null>(user);
  const [profileImage, setProfileImage] = useState<string | null>(null);

  // useEffect(() => {
  //   const fetchMapsData = () => {
  //     fetch('https://automatch-backend-585373068068.europe-west3.run.app//api/maps')
  //       .then(response => response.text())
  //       .then(data => {
  //         const script = document.createElement('script');
  //         script.textContent = data;
  //         console.log('Script:', script);
  //         document.body.appendChild(script); // Fügt das Google Maps-Skript zum HTML-Dokument hinzu
  //       })
  //       .catch(error => console.error('Error fetching maps:', error));
  //   };
  
  //   fetchMapsData();
  // }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setProfileImage(userData.sellerPhotoURL || null);
        }
      }
    };

    fetchUserProfile();
  }, [user]);

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  return (
    <Router>
      <div className="App">
        <Header user={currentUser} setUser={setCurrentUser} profileImage={profileImage} />
        <main>
          <Routes>
            <Route path="/" element={<GridView user={currentUser} />} />
            <Route path="/details/:id" element={<ProtectedRoute user={currentUser} loading={loading}><DetailView user={currentUser} /></ProtectedRoute>} />
            <Route path="/add-car" element={<ProtectedRoute user={currentUser} loading={loading}><AddCar user={currentUser} /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<ProtectedRoute user={currentUser} loading={loading}>{currentUser && <Profile user={currentUser} setProfileImage={setProfileImage} />}</ProtectedRoute>} />
            <Route path="/images/:id" element={<ProtectedRoute user={currentUser} loading={loading}><Images user={currentUser} /></ProtectedRoute>} />
            <Route path="/upload-images/:id/:existingImagesCount" element={<ProtectedRoute user={currentUser} loading={loading}><UploadImages user={currentUser} /></ProtectedRoute>} />
            <Route path="/feedback" element={<ProtectedRoute user={currentUser} loading={loading}><SendFeedback user={currentUser} version="1.0" buildNumber="1" /></ProtectedRoute>} />
            <Route path="/support" element={<ProtectedRoute user={currentUser} loading={loading}><SendSupport user={currentUser} version="1.0" buildNumber="100" /></ProtectedRoute>} />
            <Route path="/impressum" element={<ProtectedRoute user={currentUser} loading={loading}>{currentUser && <Impressum user={currentUser} setBusinessImage={setProfileImage} />}</ProtectedRoute>} />
            <Route path="/import" element={<ProtectedRoute user={currentUser} loading={loading}><ImportPage /></ProtectedRoute>} />
            {/* <Route path="/maps" element={<MapsComponent />} />  // Füge diese Route hinzu, um die Karte anzuzeigen */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
