// src/hooks/useAuth.ts
import { useEffect, useState } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
// import { useFirebaseServicesNew } from '../firebaseConfig'; // Import the hook for Firebase services

export const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  // const firebaseServices = useFirebaseServicesNew();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    // if (firebaseServices?.auth) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user);
          localStorage.setItem('user', JSON.stringify(user));
        } else {
          setUser(null);
          localStorage.removeItem('user');
        }
        setLoading(false);
      });

      return () => unsubscribe();
    // }
  }, []);

  return { user, loading };
};
