import React, { ChangeEvent } from 'react';
import { PropertyVehicleState } from './PropertyVehicleState';
import { Property }  from './DetailView';

interface DropdownOptionInt {
  value: number;
  label: string;
}

interface DropdownFieldPropsInt {
  label: string;
  fieldName: keyof Property;
  options: DropdownOptionInt[];
  value: string | number;
  onChange: (name: keyof Property, value: number) => void;
}

const DropdownFieldInt: React.FC<DropdownFieldPropsInt> = ({ label, fieldName, options, value, onChange }) => {
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = Number(event.target.value); // Convert to number
      onChange(fieldName, selectedValue); // Pass number to onChange
    };
  
    return (
      <label className="horizontal-label">
        <span className="label-text">{label}</span>
  
        <select className="editable-input" value={value} onChange={handleChange}>
          {options.map(option => (
            <option key={option.value} value={option.value}> {/* value remains a number */}
              {option.label}
            </option>
          ))}
        </select>
        <span className="edit-icon">
          <i className="fa fa-edit"></i> {/* FontAwesome edit icon */}
        </span>
      </label>
    );
  };
  
  export default DropdownFieldInt;
  
